import React from 'react';
import { demoPagesMenu } from '../menu';
import { RouteProps } from 'react-router-dom';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: demoPagesMenu.dashboard.path, element: <DefaultAside /> },
	// { path: demoPagesMenu.subscription.path, element: <DefaultAside /> },
	// { path: demoPagesMenu.documentation.path, element: <DefaultAside /> },
	// { path: demoPagesMenu.support.path, element: <DefaultAside /> },
	{ path: demoPagesMenu.installations.path, element: <DefaultAside /> },
	// { path: demoPagesMenu.notifications.path, element: <DefaultAside /> },
];

export default asides;