import { create } from 'zustand';
import { auth } from '../firebase';
import dayjs from 'dayjs';
import { persist } from 'zustand/middleware';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { sendRequest } from '.';
import { endpoints } from '../endpoints';
import Storage from './store';

type UserAccount = {
	authToken: string | null;
	isUserAuthed: boolean;
	userEmail: string;
	userName: string | null;
	userNumber: string | null;
	userId: string;
	isUserLoggedIn: boolean;
	isUserLoggedOut: boolean;
	userObserver: () => void;
	logOut: () => Promise<boolean | void>;
	logIn: (userEmail: string, userPassword: string) => Promise<boolean | void>;
	updatePassword: (newPassword: string) => Promise<unknown>;
	updateNumber: (newNumber: string) => Promise<unknown>;
	requestTokens: (userEmail: string) => Promise<boolean>;
	setStates: (stateName: string, value: string | boolean) => void;
};

export const useAccount = create(
	persist<UserAccount>(
		(set, get) => ({
			authToken: '',
			isUserAuthed: false,
			isUserLoggedIn: false,
			isUserLoggedOut: true,
			userEmail: '',
			userName: '',
			userId: '',
			userNumber: '',
			setStates: (stateName: string, value: string | boolean) => {
				set((prevState) => ({
					...prevState,
					[stateName]: value,
				}));
			},

			// async functions below
			logOut: async () => {
				try {
					// ... log out logic
					await signOut(auth);
					set({
						isUserLoggedIn: false,
						isUserLoggedOut: true,
						userEmail: '',
						userName: '',
						userNumber: '',
					});
					return true;
				} catch (error) {
					// console.error("user-logout-error: ", error);
					return false;
				}
			},
			logIn: async (userEmail: string, userPassword: string) => {
				try {
					// ... sign-in logic
					await signInWithEmailAndPassword(auth, userEmail, userPassword);

					return true;
				} catch (error) {
					// console.error("user-login-error: ", error);
					return false;
				}
			},
			updatePassword: async (newPassword: string) => {
				try {
					// ... update password logic
					return await sendRequest(endpoints.updatePassword, 'post', get().authToken, {
						email: get().userEmail,
						password: newPassword,
					});
				} catch (error) {
					// console.error("user-update-password-error: ", error);
					//@ts-ignore
					if (error?.response?.data?.message === 'email-not-verified') return false;
					return false;
				}
			},
			updateNumber: async (newNumber: string) => {
				try {
					// ... update phonenumber logic
					const result = await sendRequest(
						endpoints.updatePhoneNumber,
						'post',
						get().authToken,
						{ email: get().userEmail, phoneNumber: newNumber },
					);
					set({ userNumber: newNumber });
					return result;
				} catch (error) {
					// console.error("user-update-phoneNumber-error: ", error);
					//@ts-ignore
					if (error?.response?.data?.message === 'email-not-verified') return false;
					return false;
				}
			},
			userObserver: () => {
				onAuthStateChanged(auth, (user) => {
					if (user) {
						// User is signed in, see docs for a list of available properties
						// https://firebase.google.com/docs/reference/js/auth.user
						// console.log("user-is-signed-in: ", user);

						//@ts-ignore
						const userId = user?.reloadUserInfo.customAttributes;

						set({
							isUserLoggedIn: true,
							isUserLoggedOut: false,
							userEmail: user.email as string,
							userName: user.displayName,
							userNumber: user.phoneNumber,
							userId: JSON.parse(userId).customer_id,
							isUserAuthed: true,
						});
						// return true;
					} else {
						// User is signed out
						// ...update the 'isLoggedOut' state;
						set({
							isUserLoggedIn: false,
							isUserLoggedOut: true,
							userEmail: '',
							userName: '',
							userNumber: '',
						});
					}
				});
			},
			requestTokens: async (userEmail: string) => {
				try {
					const response = await sendRequest(
						endpoints.requestClientToken,
						'post',
						get().authToken,
						{ email: userEmail },
					);
					if (response) {
						
						set({ authToken: response.headers['auth-token'] || response.data.token, isUserAuthed: true });
						Storage.setAuthData(response.headers['auth-token'] || response.data.token);
						return true;
					}
					return false;
				} catch (error) {
					return false;
				}
			},
		}),
		{
			name: 'accountState', // unique name for the storage entry
			getStorage: () => sessionStorage, // use sessionStorage for persistence
			onRehydrateStorage: () => (state) => {
				// Optional: Add logic here to handle state rehydration, e.g., set a flag
			},
		},
	),
);

type Notifs = {
	data: Array<object> | null | any;
	isFetching: boolean;
	fetchNotifs: (url: string, token: string | null) => Promise<boolean | void>;
};

export const useNotifs = create(
	persist<Notifs>(
		(set, get) => ({
			data: [],
			isFetching: false,
			fetchNotifs: async (url: string, token: string | null) => {
				try {
					set({ isFetching: true });
					const urlMerged = url + '/mlrcn-retrieveNotificationUsageRecords';
					// const localUrl = 'http://127.0.0.1:5001/mlrc-marklite-au1-dev/us-central1/mlrcn-retrieveNotificationUsageRecords'

					const payload = { start_date: dayjs(), end_date: dayjs() };
					const result = await sendRequest(urlMerged, 'post', token, payload);
					if (result) {
						set({ isFetching: false, data: result.data.records });
						return true;
					}
				} catch (error) {
					set({ isFetching: false, data: [] });
					return false;
				}
			},
		}),
		{ name: 'notifsState', getStorage: () => sessionStorage },
	),
);

type Environments = {
	environmentData: Array<EnvTableData>;
	originalEnvironmentData: Array<any> | undefined;
	environmentDetails: Array<any> | undefined;
	selectedEnvironment: any | void;
	notificationsURL: string;
	isFetching: boolean | null;
	isFailed: boolean;
	isFetched: boolean;
	selectedEnv: any;

	//added here for sake of NOT-PERSISTING.
	sessionEnded: boolean;
	setSessionEnded: (value: boolean) => void;

	// licensesData: Array<any>;
	isSwitchingEnvironment: boolean;
	setSwitchingEnvironment: (value: boolean) => void;
	setSelectedEnvironment: (envId: string) => void;
	fetchEnvironment: (token: string, customerId: string) => Promise<boolean | void>;
};

type Licenses = {
	licensesData: Array<any>;
	originalLicensesData: Array<any> | undefined;
	licenseDetails: Array<any> | undefined;
	selectedLicense: object | undefined;
	notificationsURL: string;
	isFetching: boolean;
	isSwitchingLicense: boolean;
	setSwitchingLicense: (value: boolean) => void;
	setSelectedLicense: (envId: string) => void;
	fetchLicense: (token: string, customerId: string) => Promise<boolean | void>;
	matchLicense: (input: string) => void
	matchedLicenses: Array<any>
};

export type EnvTableData = {
	key: any;
	id: string;
	name: string;
	type: string;
	status: number;
	customer_id: string;
	customer_url?: string;
	licensesData: Array<any>;



};

export const useEnvironments = create<Environments>(
	(set, get) => ({
		sessionEnded: false,
		setSessionEnded: (value: boolean) => {
			set({ sessionEnded: value })
		},

		notificationsURL: '',
		environmentData: [],
		environmentDetails: [],
		selectedEnvironment: {},
		originalEnvironmentData: [],
		licensesData: [],
		selectedEnv: {},

		isFetching: false,
		isFetched: false,
		isFailed: false,

		isSwitchingEnvironment: false,

		setSwitchingEnvironment: (value: boolean) => {
			set({ isSwitchingEnvironment: value });
		},
		setSelectedEnvironment: (envId: string) => {
			// console.log('called-one-time');
			const result = get().originalEnvironmentData?.find(
				(env) => env.environment_id === envId,
			);
			// console.log({result});

			set({
				selectedEnv: {
					'Environment ID': result?.id,
					'Environment Name': result?.name,
					'B2C Portal URL': result.b2c_hosting_url,
					'B2B Portal URL': result.cp_hosting_url,
				}
			});
		},

		fetchEnvironment: async (token: string, customerId: string) => {
			try {
				set({ isFetching: true });
				const result = await sendRequest(endpoints.fetchEnvironment, 'post', token, {
					customer_id: customerId,
					// customer_id: 'MARKLITE',
				});

				const envs: Array<any> = result.data?.value;

				const envUrl = () => {
					if (envs[0]?.cf_hosting_url) return envs[0]?.cf_hosting_url as string;
					return envs[1]?.cf_hosting_url as string;
				};
				if (envs) {
					//@ts-ignore
					const envTableData: Array<EnvTableData> = envs.map((env, key) => {
						return {
							key: key,
							id: env.environment_id || env.fb_project_id,
							name: env.environment_name || env.fb_project_id,
							type: env.type,
							status: env.status,
							customer_id: env.customer_id,
						};
					});



					//@ts-ignore
					set({
						environmentData: envTableData,
						isFetched: true,
						originalEnvironmentData: envs,
						notificationsURL: envUrl(),
					});
					if (envTableData.length == 0) return false;
					return true;
				}
			} catch (error) {
				set({ isFailed: true, environmentData: [] });
				return false;
			}
		},
	}),
);
export const useLicenses = create(
	persist<Licenses>(
		(set, get) => ({
			notificationsURL: '',
			licensesData: [],
			licenseDetails: [],
			selectedLicense: {},
			originalLicensesData: [],
			matchedLicenses: [],

			isFetching: false,

			isSwitchingLicense: false,

			setSwitchingLicense: (value: boolean) => {
				set({ isSwitchingLicense: value });
			},
			setSelectedLicense: (license_id: string) => {
				set({
					selectedLicense: get().originalLicensesData?.find(
						(license) => license.license_id === license_id,
					),
				});
			},

			matchLicense: (input: string) => {
				set({
					matchedLicenses: get().licensesData?.find(
						(license) => input === license.id,
					)
				})
			},

			fetchLicense: async (token: string, customerId: string) => {
				try {
					set({ isFetching: true });
					const result = await sendRequest(endpoints.fetchLicenses, 'post', token, {
						customer_id: customerId,
						// customer_id: 'MARKLITE',
					});
					const licenses: Array<any> = result.data?.value;
					// console.log({lic: result});

					// console.log({ licenseData: licenses });
					if (licenses) {
						//@ts-ignore
						const licenseData: Array<any> = licenses.map((li, key) => {
							return {
								id: li.license_id,
								name: li.license_name,
								type: li.license_type,
								limitQuantity: li.limit_quantity,
								company: li.lic_company,
								url: li.lic_company,
								date: dayjs(li.expiry_date).format('DD/MM/YYYY')
							};
						});

						//@ts-ignore
						set({
							isFetching: false,
							licensesData: licenseData,
							// originalLicensesData: envs,
							// notificationsURL: envUrl(),
						});
						if (licenseData.length == 0) return false;
						return true;
					}
				} catch (error) {
					set({ isFetching: false, licensesData: [] });
					return false;
				}
			},
		}),
		{
			name: 'licenseState',
			getStorage: () => sessionStorage,
		},
	),
);







