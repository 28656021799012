import React, { FC, ReactNode, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import { useNavigate } from 'react-router';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import { useAccount } from '../../../services/state';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
	showFullScreen?: boolean;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({
	beforeChildren,
	afterChildren,
	showFullScreen,
}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { logOut } = useAccount();
	const navigate = useNavigate();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
		className: 'flex items-center justify-center text-black',
	};

	const { i18n } = useTranslation();

	const handleLogOut = async () => {
		await logOut();
		navigate('/login');
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div> */}

				{/*	Full Screen */}
				{showFullScreen ? (
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>
				) : null}

				{/* Logout user */}
				<div className='col-auto'>
					<Popovers
						trigger='hover'
						desc='Logout'
						className='flex items-center justify-center'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={handleLogOut} //todo: execute logOut function here
							className='btn-only-icon'
							data-tour='log-out'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 20 20'
								fill='currentColor'
								className='text-black w-7 h-7'>
								<path
									fillRule='evenodd'
									d='M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75V4.25Z'
									clipRule='evenodd'
								/>
								<path
									fillRule='evenodd'
									d='M6 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H6.75A.75.75 0 0 1 6 10Z'
									clipRule='evenodd'
								/>
							</svg>
						</Button>
					</Popovers>
				</div>
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
