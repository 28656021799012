import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/img/logo.svg';

import Logo from '../../components/Logo';

type IBrandProps = {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown; //ts-ignore
};

const Brand: FC<IBrandProps | any> = ({ asideStatus, setAsideStatus }) => {
	const [displayImage, setDisplayImage] = React.useState<boolean>(false);
	useEffect(() => {
		if (process.env.REACT_APP_TENANT_URL) {
			setDisplayImage(true);
			return;
		}
		setDisplayImage(false);
	}, []);

	const navigate = useNavigate();
	const currentState = Date.now();

	return (
		<div className='p-0 brand'>
			<div className='p-0 m-0 brand-logo'>
				<h1
					className='cursor-pointer brand-title'
					onClick={() => {
						const diff = Date.now() - currentState;
						if (diff > 120000) {
							navigate('/');
						}
					}}>
					<Logo height={60} roundedEnd alwaysLight />
				</h1>
			</div>

			<button
				type='button'
				className='btn brand-aside-toggle bg-primary'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}
				style={asideStatus ? { padding: 2, marginLeft: '5px', marginRight: '10px' } : {}}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
		// <div className='brand'>
		// 	<div className='brand-logo'>
		// 		<h1 className='brand-title '>
		// 			<Link to='/dashboard' aria-label='Logo'>
		// 				{!displayImage ? (
		// 					<ReactSVG src={logo} className='w-[10px] h-[10px]' />
		// 				) : (
		// 					<img
		// 						src={process.env.REACT_APP_TENANT_URL}
		// 						alt='customer_logo'
		// 						className='relative w-full h-full top-3'
		// 					/>
		// 				)}
		// 			</Link>
		// 		</h1>
		// 	</div>
		// 	<button
		// 		type='button'
		// 		className='relative btn brand-aside-toggle top-3'
		// 		aria-label='Toggle Aside'
		// 		onClick={() => setAsideStatus(!asideStatus)}>
		// 		<Icon icon='FirstPage' className='brand-aside-toggle-close' />
		// 		<Icon icon='LastPage' className='brand-aside-toggle-open' />
		// 	</button>
		// </div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
