import { useEnvironments } from '../../../../src/services/state'
import React, { useEffect } from 'react'

function Timeout() {
    const { setSessionEnded } = useEnvironments()

    useEffect(() => {
        setTimeout(() => {
            setSessionEnded(false);
        }, 5000);
    }, [])

    return (

        <div className='flex flex-col justify-between gap-2 bg-white rounded-lg min-h-fit w-[400px] px-3 py-2'>
            <section className='flex flex-col gap-3 h-[80%] py-3'>
                <span className='flex flex-col items-center justify-center gap-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                    </svg>
                    <h1 className='font-semibold text-center text-black'>Session Timeout!</h1>
                </span>
            </section>

            <section className='w-full h-[20%] rounded-lg overflow-hidden pb-2 px-4'>
                <button onClick={() => setSessionEnded(false)} className='w-full py-3 bg-[#111142] duration-300 hover:bg-[#1111428a] focus:bg-[#1111428a] text-gray-100 rounded-lg'>Continue</button>
            </section>
        </div>

    )
}

export default Timeout