import React from 'react';
import { RouteProps } from 'react-router-dom';
import { demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	{ path: '/login', element: null },
	// { path: demoPagesMenu.dashboard.path, element: <DefaultFooter /> },
	// { path: demoPagesMenu.installations.path, element: <DefaultFooter /> },
];

export default footers;
