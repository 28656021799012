import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { useAccount } from '../services/state';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<any>({} as any);

interface IAuthContextProviderProps {
	children: ReactNode;
}
// export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
export const AuthContextProvider: FC<any> = ({ children }) => {
	const { isUserLoggedIn, isUserLoggedOut, userObserver } = useAccount();
	useEffect(userObserver, []);

	return <> {children} </>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
