import destr from "destr"

/** this store class uses Session storage to store all resources fetched from the database and clears the store when the user logs out or closes the browser.  */
export default class Storage {
    static getPreviousFiles() {
        return JSON.parse(sessionStorage.getItem('previous') as string);
    }
    static setPreviousFiles(value: any) {
        return sessionStorage.setItem('previous', JSON.stringify(value));
    }

    static getOriginalFiles() {
        return JSON.parse(sessionStorage.getItem('original') as string);
    }
    static setoriginalFiles(value: any) {
        return sessionStorage.setItem('original', JSON.stringify(value));
    }
    
    static setRestructuredFiles (value: any) {
        return sessionStorage.setItem('restructured', JSON.stringify(value));
    }
    static getRestructuredFiles () {
        return JSON.parse(sessionStorage.getItem('restructured') as string);
    }

    static setStore(value: any) {
        return sessionStorage.setItem("accountState", JSON.stringify(value))
    }
    static getStore() {
        return JSON.parse(sessionStorage.getItem("accountState") as string)
    }
    static get(key: string) {
        return JSON.parse(sessionStorage.getItem(key) as string);
    }
    static set(key: string, value: any) {
        return sessionStorage.setItem(key, JSON.stringify(value));
    }
    static del(key: string) {
        return sessionStorage.removeItem(key);
    }
    static setAuthData(value: string) {
        return sessionStorage.setItem('authToken', JSON.stringify(value));
    }
    static getAuthData() {
        return destr<string>(sessionStorage.getItem('authToken') as string);
    }
    static popAuthData() {
        return sessionStorage.removeItem("authToken")
    }
    static delAllFiles() { 
        sessionStorage.clear()
        return true;
    }
}