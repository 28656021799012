import { checkURL } from '../../../../src/services';
import { useAccount } from '../../../../src/services/state';
import React, { useEffect, useState } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import Signin from '../auth/Signin';

function AuthProvider({ child }: { child: React.ReactNode }) {
    const navigate = useNavigate();
    const { isUserAuthed, isUserLoggedOut } = useAccount();
    const [nodeToRender, setNodeToRender] = useState<React.ReactNode | React.ReactElement>(
       <div className='flex flex-col items-center justify-center w-full h-screen'>
         <PulseLoader color='#172554' />
       </div>,
    );
   
    useEffect(() => {
       if (checkURL(window.location.href)) {
         setNodeToRender(<Signin />);
       } else if (!checkURL(window.location.href) && !isUserLoggedOut) {
         setNodeToRender(child);
       } else if (!checkURL(window.location.href) && isUserLoggedOut) {
         navigate('/login');
         setNodeToRender(<Signin />);
       }
    }, [isUserAuthed, isUserLoggedOut]);
   
    return nodeToRender;
   }

export default AuthProvider;