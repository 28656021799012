import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: demoPagesMenu.dashboard.path, element: <DashboardHeader /> },
	// { path: demoPagesMenu.documentation.path, element: <DashboardHeader /> },
	// { path: demoPagesMenu.installations.path, element: <DashboardHeader /> },
	// { path: demoPagesMenu.subscription.path, element: <DashboardHeader /> },
	// { path: demoPagesMenu.support.path, element: <DashboardHeader /> },
];

export default headers;
