import React, { FC } from 'react';
import PropTypes from 'prop-types';

import useDarkMode from '../hooks/useDarkMode';
import defaultLogo from '../assets/img/rc-with-icon.svg';
import defaultLogoDark from '../assets/img/rc-with-icon-dark.svg';

interface ILogoProps {
	width?: number;
	height?: number;
	roundedEnd?: boolean;
	defaultlogo?: boolean;
	alwaysLight?: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, roundedEnd, defaultlogo, alwaysLight }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();

	let imageUri = window.localStorage.getItem('logo');
	if (imageUri) {
		imageUri = imageUri.replaceAll('"', '');
	}

	return (
		<>
			{imageUri?.length && !defaultLogo ? (
				<img
					src={imageUri as string}
					alt={'logo.svg'}
					style={{
						objectFit: 'contain',
						// height: '55px',

						background: '#ffffff',
						// padding: '5px',
						// paddingRight: '25px',
						// borderBottomRightRadius: '30px',
						// borderTopRightRadius: '30px',
					}}
					width={height !== 854 && !!height ? height * (2155 / 854) : width}
					height={width !== 2155 && !!width ? width * (854 / 2155) : height}
				/>
			) : (
				<img
					src={!darkModeStatus || alwaysLight ? defaultLogo : defaultLogoDark}
					alt={'logo.svg'}
					style={{
						objectFit: 'contain',
						// height: '55px',

						// background: '#ffffff',
						// padding: '5px',
						// paddingRight: '25px',
						// borderBottomRightRadius: '30px',
						// borderTopRightRadius: '30px',
					}}
					width={height !== 854 && !!height ? height * (2155 / 854) : width}
					height={width !== 2155 && !!width ? width * (854 / 2155) : height}
				/>
			)}
		</>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;