import axios, { AxiosError } from "axios";
import { endpoints } from "../endpoints";
import Storage from "./store";

export const ErrorCases = ["Network Error", "Request Timed-out", "Request Failed", "Something went wrong"]

/** `type` can be either: `post` or `get`  */
export async function sendRequest(url: string, type: "post" | "get", token: string | null, body?: object | null) {
    let headers = {
        "Content-Type": "application/json",
        "Accept": "*",
        "auth-token": token
    }
    return axios.post(url, body, { headers });
}


const errorParser = (error: any) => {
    switch (error) {
        case "ERR_NETWORK":
            return "Network Error"

        case "ECONNABORTED":
            return "Request Timed-out"

        case "ERR_BAD_REQUEST":
            return "Request Failed"

        default:
            return "Something went wrong"
    }
}

export async function getAllFiles(token: string | null, email: string, customerId: string) {
    try {
        const response = await sendRequest(endpoints.fetchStorageFiles, 'post', token, { email, content: "all", customer_id: customerId });
        // console.log(response?.data?.result?.files?.children[0]?.children);

        return response?.data?.result?.files
    } catch (error: AxiosError | any) {
        return errorParser(error?.code);
    }
}

export function checkURL(url: string) {
    const regex = /(\/login)|(\/signup)|(.*)/;
    const result = url.match(regex) as RegExpMatchArray

    if (result[1]) {
        return true
        // return "URL contains '/login'";
    } else if (result[2]) {
        return true
        // return "URL contains '/signup'";
    }
    return false;
}

export function getInitChars(str: string) {
    return str
        .split(' ')
        .map(word => word.charAt(0))
        .join('');
}

export function startCountdown(duration: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        // Check if a countdown is already in progress and use its remaining time
        const savedEndTime = Storage.get('countdownEndTime');
        const now = Date.now();
        let endTime: number;

        if (savedEndTime && now < parseInt(savedEndTime, 10)) {
            endTime = parseInt(savedEndTime, 10);
        } else {
            endTime = now + duration * 1000;
            Storage.set('countdownEndTime', endTime.toString());
        }

        const tick = () => {
            const now = Date.now();
            const remainingTime = endTime - now;

            if (remainingTime <= 0) {
                // Countdown has finished
                Storage.del('countdownEndTime'); // Clean up
                resolve(true); // Resolve the promise with true
            } else {
                // Update the remaining time every second
                setTimeout(tick, 1000);
                // Although not changing, the end time is re-affirmed in session storage, might be redundant
                Storage.set('countdownEndTime', endTime.toString());
            }
        };
        tick();
    });
}
