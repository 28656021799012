import React, { lazy } from 'react';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Signin from '../pages/presentation/auth/Signin';
import { Navigate } from 'react-router-dom';
import NotificationLogs from '../pages/presentation/page-layouts/NotificationLogs';

const Subscription = lazy(() => import('../pages/presentation/page-layouts/Subscription'));
const Documentation = lazy(() => import('../pages/presentation/page-layouts/Documentation'));
const Installation = lazy(() => import('../pages/presentation/page-layouts/InstallationFiles'));
const Support = lazy(() => import('../pages/presentation/page-layouts/Support'));

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const presentation = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	// {
	// 	path: demoPagesMenu.subscription.path,
	// 	element: <Subscription />,
	// },
	// {
	// 	path: demoPagesMenu.documentation.path,
	// 	element: <Documentation />,
	// },
	{
		path: demoPagesMenu.installations.path,
		element: <Installation />,
	},
	// {
	// 	path: demoPagesMenu.support.path,
	// 	element: <Support />,
	// },
	// {
	// 	path: demoPagesMenu.notifications.path,
	// 	element: <NotificationLogs />,
	// },
	{
		path: '/',
		element: <Navigate to="/login" replace />,
	},
	{
		path: "/login",
		element: <Signin />,
	},
	{
		path: '*',
		element: <AUTH.PAGE_404 />,
	},

];
const contents = [...presentation];

export default contents;