import React, { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import { useAccount } from '../../services/state';
import { PulseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const User = () => {
	const { userName } = useAccount();
	const { t } = useTranslation(['translation', 'menu']);
	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const { logOut } = useAccount();
	const navigate = useNavigate();

	const handleLogOut = async () => {
		await logOut();
		navigate('/login');
	};
	return (
		<>
			{/* <div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				// onClick={() => setCollapseStatus(true)}
			>
				<div className='user-avatar'>
					<Icon icon='Person' size='2x' color='primary' />
				</div>
				<div className='user-info'>
					<div className='user-name'>
						{userName || <PulseLoader color='#fff' size={8} />}
					</div>
					<div className='user-sub-title'></div>
				</div>
			</div> */}

			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={handleLogOut}>
				<div className='ml-[5.8px] user-avatar'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'
						fill='currentColor'
						className='text-[#6d5dd3] w-7 h-7'>
						<path
							fillRule='evenodd'
							d='M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75V4.25Z'
							clipRule='evenodd'
						/>
						<path
							fillRule='evenodd'
							d='M6 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H6.75A.75.75 0 0 1 6 10Z'
							clipRule='evenodd'
						/>
					</svg>
				</div>
				<div className='user-info'>
					<div className='user-name'>Logout</div>
					<div className='user-sub-title'></div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div role='presentation' className='navigation-item'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
