import classNames from 'classnames';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Logo from '../../../components/Logo';
import { FC, useEffect, useState } from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { useFormik } from 'formik';
import { useAccount, useEnvironments } from '../../../services/state';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import { Modal } from '@mui/material';
import Timeout from '../page-layouts/Timeout';
import Storage from '../../../services/store';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='mt-5 text-center h1 fw-bold'>Create Account,</div>
				<div className='mb-5 text-center h4 text-muted'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='mt-5 text-center h3 fw-bold text-blue-950'>RouteCTRL Connect</div>
			{/* <div className='mt-2 text-center h4 fw-bold'>Customer Portal</div> */}
			<div className='mb-5 text-center h5 text-muted text-primary'>Sign in to continue</div>
		</>
	);
};
const Signin = () => {
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [timedOut, setTimedOut] = useState(false);
	const navigate = useNavigate();

	const { logIn, isUserAuthed, requestTokens, authToken, logOut } = useAccount();
	const { sessionEnded, setSessionEnded } = useEnvironments()
	const [showTimeout, setShowTimeout] = useState(false);


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: email,
			loginPassword: password,
		},

		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);

			try {
				const res = await logIn(values.loginUsername, values.loginPassword);
				if (res) return navigate('/dashboard');
			} catch (error: any) {
				switch (error.code) {
					case 'auth/invalid-credentials':
						setErrorMessage('Incorrect email or password');
				}

				setIsLoading(false);
				setIsError(true);
			}

			setIsLoading(false);
		},
	});

	const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	// Function to handle session end
	async function endSession() {
		setSessionEnded(true);
		Storage.popAuthData();
		await logOut(); // ensures user cannot be granted access to routes if they navigate back.
		navigate("/", { replace: true });
		Storage.del('sessionStart');
	}

	function startSessionTimeout() {
		Storage.set('sessionStart', Date.now().toString());
		setTimeout(endSession, 2400000);
	  }

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setIsError(false);
		setIsLoading(true);
		if (!email || !password) {
			setErrorMessage('Please fill in all fields');
			setIsLoading(false);
			return setIsError(true);
		}
		if (password.length < 6) {
			setErrorMessage('Password cannot be less than 6 characters');
			setIsLoading(false);
			return setIsError(true);
		}
		try {
			const [res, tokens] = await Promise.all([
				logIn(email, password),
				await requestTokens(email),
			]);

			// const res = await logIn(email, password);
			if (!res) {
				setErrorMessage('Incorrect Email or Password!');
				setIsLoading(false);
				setIsError(true);
				return;
			}

			if (!tokens) {
				setErrorMessage('Authorization failed, try again!');
				setIsLoading(false);
				setIsError(true);
				return;
			}
			else {
				startSessionTimeout()
				navigate('/dashboard', { replace: true });
				setTimeout(async () => {
					setSessionEnded(true);
					Storage.popAuthData();
					await logOut(); // ensures user cannot be granted access to routes if they navigate back.
					navigate("/", { replace: true });
				}, 2400000);
				// }, 10000);
			}

		} catch (error) {
			// console.log({ error });
			setErrorMessage('Authorization failed, try again!');
			setIsLoading(false);
			setIsError(true);
		}

	};

	useEffect(() => {
		if (timedOut === true) {

		}
	}, [timedOut])

	useEffect(() => {
		if (isError) {
			setTimeout(() => {
				setIsError(false);
			}, 4000);
		}
	}, [isError]);


	useEffect(() => {
		if (sessionEnded) {
			setShowTimeout(true)
			setTimeout(() => {
				setShowTimeout(false)
			}, 5000);
		}
		else setShowTimeout(false)
	}, [sessionEnded])

	return (
		<>
			<Modal
				className="grid place-items-center"
				sx={{
					backdropFilter: "blur(2px)",
					backgroundColor: "rgba(0, 0, 0, 0.24)",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={showTimeout}
			>
				<Timeout />
			</Modal>
			<PageWrapper>
				<Page>
					<div className='row h-100 align-items-center justify-content-center'>
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='bg-white shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									<div className='mx-24 my-5 text-center text-white'>
										<Logo width={300} alwaysLight />
									</div>

									<LoginHeader isNewUser={false} />

									<form className='row g-4'>
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email'
													className='mb-4'>
													<Input
														autoComplete='username'
														value={email}
														onChange={handleSetEmail}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={formik.errors.loginUsername}
														isValid={formik.isValid}
														onBlur={formik.handleBlur}
														style={{ fontSize: '16px' }}
														type='email'
														required
														className='bg-white'
													/>
												</FormGroup>

												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='current-password'
														value={password}
														onChange={handleSetPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={formik.errors.loginPassword}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onKeyDown={(e) => {
															if (e.key === 'Enter') {
																formik.handleSubmit();
															}
														}}
														onBlur={formik.handleBlur}
														required
														aria-autocomplete='both'
														className='bg-white'
													/>
												</FormGroup>
											</div>
											<div className='mt-4 mb-4 col-12'>
												<Button
													color='warning'
													className='py-3 w-100'
													isDisable={isLoading}
													onClick={handleSubmit}>
													Login
													{isLoading && (
														<Spinner
															isSmall
															inButton
															isGrow
															className='ms-3'
														/>
													)}
												</Button>
												{isError ? (
													<Alert
														isLight={true}
														severity='error'
														icon='Warning'
														color='warning'
														className='flex flex-row items-center w-[100%] md:w-[100%] my-2'>
														{errorMessage}
													</Alert>
												) : null}
											</div>
										</>
									</form>
								</CardBody>
							</Card>
							<div className='text-center '>
								<a
									href='https://www.marklite.com/privacy-policy/'
									className={classNames('text-decoration-none me-3', 'text-dark')}>
									Privacy policy
								</a>
								<a
									href='https://www.marklite.com/terms-conditions/'
									className={classNames(
										'link-dark text-decoration-none',
										'text-dark',
									)}>
									Terms of use
								</a>
							</div>
						</div>
					</div>
				</Page>
			</PageWrapper>
		</>
	);
};

export default Signin;
