export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
	// documentation: {
	// 	id: 'documentation',
	// 	text: 'Documentation',
	// 	path: '/documentation',
	// 	icon: 'FolderSpecial',	
	// },
	installations: {
		id: 'installation',
		text: 'Installation Files',
		path: 'installationfiles',
		icon: 'SystemUpdateAlt',
	},
	// subscription: {
	// 	id: 'subscription',
	// 	text: 'Subscription',
	// 	path: 'subscription',
	// 	icon: 'CreditCard',
	// },
	// notifications: {
	// 	id: 'notifications',
	// 	text: 'Notification Logs',
	// 	path: 'notificationlogs',
	// 	icon: 'Notifications'
	// },
	// support: {
	// 	id: 'support',
	// 	text: 'Support Page',
	// 	path: 'support',
	// 	icon: 'SupportAgent',
	// },
};

export const pageLayoutTypesPagesMenu = {
	// layoutTypes: {
	// 	id: 'layoutTypes',
	// 	text: '',
	// },
	// blank: {
	// 	id: 'blank',
	// 	text: 'Blank',
	// 	path: 'page-layouts/blank',
	// 	icon: 'check_box_outline_blank ',
	// },
	// pageLayout: {
	// 	id: 'pageLayout',
	// 	text: 'Page Layout',
	// 	path: 'page-layouts',
	// 	icon: 'BackupTable',
	// 	subMenu: {
	// 		headerAndSubheader: {
	// 			id: 'headerAndSubheader',
	// 			text: 'Header & Subheader',
	// 			path: 'page-layouts/header-and-subheader',
	// 			icon: 'ViewAgenda',
	// 		},
	// 		onlyHeader: {
	// 			id: 'onlyHeader',
	// 			text: 'Only Header',
	// 			path: 'page-layouts/only-header',
	// 			icon: 'ViewStream',
	// 		},
	// 		onlySubheader: {
	// 			id: 'onlySubheader',
	// 			text: 'Only Subheader',
	// 			path: 'page-layouts/only-subheader',
	// 			icon: 'ViewStream',
	// 		},
	// 		onlyContent: {
	// 			id: 'onlyContent',
	// 			text: 'Only Content',
	// 			path: 'page-layouts/only-content',
	// 			icon: 'WebAsset',
	// 		},
	// 	},
	// },
	// asideTypes: {
	// 	id: 'asideTypes',
	// 	text: 'Aside Types',
	// 	path: 'aside-types',
	// 	icon: 'Vertical Split',
	// 	subMenu: {
	// 		defaultAside: {
	// 			id: 'defaultAside',
	// 			text: 'Default Aside',
	// 			path: 'aside-types/default-aside',
	// 			icon: 'ViewQuilt',
	// 		},
	// 		minimizeAside: {
	// 			id: 'minimizeAside',
	// 			text: 'Minimize Aside',
	// 			path: 'aside-types/minimize-aside',
	// 			icon: 'View Compact',
	// 		},
	// 	},
	// },
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};




// if the 'resource' argument is an object containing an array property 'children', then loop through each object in 'children' array and render using the existing switch statement we have in place, else if it is an array of objects, loop through the array and render all using the switch logic we already have in place now.