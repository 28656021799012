import { FC, ReactNode, createContext, useState } from "react";
type DateContextType = {
  date: {
    start: string;
    end: string;
  };
  setDate: React.Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
    }>
  >;
};

export const DateContext = createContext<DateContextType | null>(null);
export const DateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [date, setDate] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  return (
    <DateContext.Provider value={{ date, setDate }}>
      {children}
    </DateContext.Provider>
  );
};
