export const endpoints = {
	fetchStorageFiles: `${process.env.REACT_APP_BASE_URL}mlrcc-RetrieveStorageResources`,
	fetchStorageFilesLocal: `${process.env.REACT_APP_LOCAL_URL}mlrcc-RetrieveStorageResources`,
	fetchNotifications: `${process.env.REACT_APP_BASE_URL}mlrcn-retrieveNotificationUsageRecords`,
	// fetchEnvironment: `${process.env.REACT_APP_TARDIS_URL}getCustomerEnvironments`,
	fetchEnvironment: `${process.env.REACT_APP_TARDIS_URL}getCustomerEnvironments`,
	fetchLicenses: `${process.env.REACT_APP_TARDIS_URL}getCustomerLicenses`,

	createUserAccount: `${process.env.REACT_APP_BASE_URL}mlrcc-CreateUserAccount`,
	requestClientToken: `${process.env.REACT_APP_BASE_URL}mlrcc-RequestSecurityToken`,
	updatePassword: `${process.env.REACT_APP_BASE_URL}mlrcc-UpdateUserPassword`,
	updatePhoneNumber: `${process.env.REACT_APP_BASE_URL}mlrcc-UpdateUserPhoneNumber`,
};
